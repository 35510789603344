<template>
  <div>
    <div id="google_translate_element"></div>
  </div>
</template>
<script>
export default {
  name: 'GoogleTranslate',
  mounted() {
    this.loadGoogleTranslateScript();
  },
  methods: {
    loadGoogleTranslateScript() {
      if (!document.getElementById('google-translate-script')) {
        const script = document.createElement('script');
        script.id = 'google-translate-script';
        script.src =
            'https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
        script.async = true;
        document.body.appendChild(script);

        // Attach the global callback after adding the script
        window.googleTranslateElementInit = this.googleTranslateElementInit;
      }
    },
    googleTranslateElementInit() {
      if (window.google && window.google.translate) {
        new window.google.translate.TranslateElement(
            {
              pageLanguage: 'en',
              includedLanguages: 'es,en',
              layout: window.google.translate.TranslateElement.InlineLayout.SIMPLE,
              autoDisplay: false,
            },
            'google_translate_element'
        );
      } else {
        console.error('Google Translate library is not loaded.');
      }
    },
  },
};
</script>