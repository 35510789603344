<template>
  <section class="innerpage-wrap">
    <div class="container" ref="saveAgreement">
      <div class="split-title">
        <h1 class="brand-color">Agreement & Signature</h1>
      </div>
      <div class="enrollmentForm card-block">
        <form action="">
          <h3 class="mb-4">Agreement, Application and Authorization</h3>
          <div class="notes mb-4" v-for="(agreementText, index) in agreementTexts" :key="agreementText.text_body">
            <div v-html="agreementText.text_head">
            </div>
            <div v-html="agreementText.text_body">
            </div>
            <div class="form-check form-check-inline mt-3">
              <input :class="[agreementText.agreementErr ? 'form-check-input agreement-checkbox is-invalid' : 'form-check-input agreement-checkbox']"
                     @change="checkboxValid(index)"
                     v-model="agreementText.agreement" type="checkbox"  id="inlinecheckbox1" :value="'agreed' + index">
              <label class="form-check-label agreement-text" for="inlinecheckbox1" >{{agreementText.text_required}}</label><br />
              <span class="error_message" v-if="agreementText.agreementErr">
                      This field is required.
                    </span>
            </div>
          </div>
          <div class="mb-3">
            <carrier-message-alert :alertMessage="carrierSpecialMessage"/>
          </div>
          <div class="fomr-group row">
            <h3 v-if="formFields.agent_name.required">Broker Verification</h3>
            <h3 v-if="formFields.group_name.required">Group Verification</h3>
            <h3 v-if="formFields.user_name.required">Applicant Verification</h3>
            <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12  mb-4" v-if="formFields.question.required">
              <label>Verification Question <span class="required">*</span></label>
              <input type="text"
                     class="custom-input"
                     name=""
                     id=""
                     v-model="formFields.question.value"
                     value=""
                     :readonly="formFields.question.read_only">
              <div class="error_message" v-if="formErrorFlag == true">{{formErrors['question_message']}}</div>
            </div>
            <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12  mb-4" v-if="formFields.answer.required">
              <label>Verify Answer <span class="required">*</span></label>
              <input type="text"
                     class="custom-input"
                     placeholder="Enter Verification Answer"
                     name=""
                     id=""
                     @input="$v.formFields.answer.value.$touch(); removeError();"
                     :class="{'form-control is-invalid':$v.formFields.answer.value.$error || answer_error}"
                     v-model="formFields.answer.value"
                     value=""
                     :readonly="formFields.answer.read_only">
              <div class="form-group__message text-left ml-3 mt-2"  v-if="!$v.formFields.answer.value.required && $v.formFields.answer.value.$error">
                Field is required
              </div>
              <div class="error_message" v-if="formErrorFlag == true">{{answer_error}}</div>
            </div>
            <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12  mb-4" v-if="formFields.agent_name.required">
              <label>Broker <span class="required">*</span></label>
              <input type="text" disabled class="custom-input" placeholder="Broker Name" name="" id="" v-model="formFields.agent_name.value" value="" :readonly="formFields.agent_name.read_only">
              <div class="error_message" v-if="formErrorFlag == true">{{formErrors['agent_name_message']}}</div>
            </div>
            <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12  mb-4" v-if="formFields.group_name.required">
              <label>Group <span class="required">*</span></label>
              <input type="text"
                     class="custom-input"
                     placeholder="Broker Name"
                     name=""
                     id=""
                     v-model="formFields.group_name.value"
                     value=""
                     :readonly="formFields.group_name.read_only">
              <div class="error_message" v-if="formErrorFlag == true">{{formErrors['group_name_message']}}</div>
            </div>
            <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12  mb-4" v-if="formFields.user_name.required">
              <label>Electronic Signature <span class="required">*</span></label>
              <input type="text" disabled class="custom-input" placeholder="Broker Name" name="" id="" v-model="formFields.user_name.value" value="" :readonly="formFields.user_name.read_only">
              <div class="error_message" v-if="formErrorFlag == true">{{formErrors['user_name_message']}}</div>
            </div>
          </div>

          <div class="form-group row">
            <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12  mb-4" v-if="formFields.date.required">
              <label>Date<span class="required">*</span></label>

              <input
                  v-model="formFields.date.value"
                  value=""
                  disabled
                  :readonly="formFields.date.read_only"
                  class="custom-input"
                  placeholder="mm/dd/yyyy"
                  type="text" />
              <div class="error_message" v-if="formErrorFlag == true">{{formErrors['date_message']}}</div>
            </div>
            <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12  mb-4" v-if="formFields.agent_code.required">
              <label>Broker Code<span class="required">*</span></label>
              <input type="text" disabled class="custom-input" placeholder="Broker Code" name="" id="" v-model="formFields.agent_code.value" value="" :readonly="formFields.agent_code.read_only">
              <div class="error_message" v-if="formErrorFlag == true">{{formErrors['agent_code_message']}}</div>
            </div>
            <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12  mb-4" v-if="formFields.group_code.required">
              <label>Group Code<span class="required">*</span></label>
              <input type="text" disabled class="custom-input" placeholder="Broker Code" name="" id="" v-model="formFields.group_code.value" value="" :readonly="formFields.group_code.read_only">
              <div class="error_message" v-if="formErrorFlag == true">{{formErrors['group_code_message']}}</div>
            </div>
          </div>
          <div class="list" v-show="signature_flag">
            <div class="head">
              <label>Application Signature<span class="required">*</span> (Sign Name using your mouse or touch screen in the box below and click complete)</label>
            </div>
            <div class="content sign">
              <div>
                <canvas class="signature" id="signatureCanvas"></canvas>
                <div class="error_message" v-if="signatureError">Signature is required</div>
              </div>
              <div class="action-btn split-title mt-2">
                <router-link to="" @click.native="clear" class="button-brand">Clear</router-link>
              </div>
            </div>
          </div>
        </form>
      </div>
      <b-modal v-bind:hide-footer="true" id="code-send" centered size="md" >
        <template #modal-header>
          <div class="popUp-header" style="border-bottom: 0px !important">
            <div  v-if="saveSuccess">
              <h4 style="color: green"><b>Information Saved!!!</b> Check your email to continue</h4>
            </div>
            <div v-else>
              <h4>Please enter the alternate email you want to send Enrollment code to?</h4>
            </div>
            <b-button variant="close-btn" @click="$bvModal.hide('code-send')">
              <i class="fas fa-times"></i>
            </b-button>
          </div>
        </template>
        <b-container fluid>
          <div v-if="saveSuccess==false" class="input_box client_email">
            <input type="text"
                   class="custom-input"
                   :class="{'form-control is-invalid': email && !isEmailValid || isEmailNull}"
                   @input="emailValid(); removeError();"
                   v-model="email"
                   value="" />
            <div class="form-group__message  ml-3 mt-2" v-if="email && !isEmailValid || isEmailNull">
              Enter valid email address
            </div>
            <div class="error_message" v-if="formErrorFlag == true">{{formErrors['email_message']}}</div>
          </div>
          <div class="popUp-details text-center">
            <div v-if="saveSuccess" class="action-btn text-center mt-4">
              <b-button style="width:100%;"  class="button-brand mx-1" @click="$bvModal.hide('code-send')" data-dismiss="modal">Done</b-button>
            </div>
            <div v-else class="action-btn text-center mt-4">
              <b-button style="width:40%;"  class="dark-btn mx-1" @click="$bvModal.hide('code-send')">Back</b-button>
              <b-button style="width:40%;"  class="button-brand mx-1" @click="saveAndContinueEnrollment" data-dismiss="modal">Send Code</b-button>
            </div>
          </div>
        </b-container>

      </b-modal>
      <div class="action-btn split-title mt-5">
        <router-link to="" @click.native="navigatePrevious" class="dark-btn back-action">Back</router-link>
          <div class="action-right-wrap">
            <cancel-enrollment-button />
            <router-link to="" @click.native="saveEnrollment" class="button-green"  v-if="siteRedirection !== 'usag'">Save & Continue Later</router-link>
            <router-link to="" @click.native="saveData" class="button-brand btn-brand">Next</router-link>
          </div>
      </div>
    </div>
  </section>
</template>
<style>
.signature {
  border: 2px solid #cbc9c6;
  border-radius: 5px;
}
</style>
<script>
import axios from 'axios';
import {required} from "vuelidate/lib/validators";
import SignaturePad from 'signature_pad';
import html2canvas from 'html2canvas';
import {checkEmail} from "../../utils/validators";
import CarrierMessageAlert from "../CarrierMessageAlert";
import CancelEnrollmentButton from "../CancelEnrollmentButton.vue";

export default {
  name: "AgreementAndSignature",
  components: {
    CarrierMessageAlert,
    CancelEnrollmentButton
  },
  data: function () {
    return {
      tempId: '',
      agreementTexts: [],
      formFields: {
        question_id: {
          value: '',
          required: false,
          read_only: false,
        },
        question: {
          value: '',
          required: false,
          read_only: false,
        },
        answer: {
          value: '',
          required: false,
          read_only: false,
        },
        verification_code: {
          value: '',
          required: false,
          read_only: false,
        },
        date: {
          value: '',
          required: false,
          read_only: false,
        },
        agent_name: {
          value: '',
          required: false,
          read_only: false,
        },
        agent_code: {
          value: '',
          required: false,
          read_only: false,
        },
        group_name: {
          value: '',
          required: false,
          read_only: false,
        },
        group_code: {
          value: '',
          required: false,
          read_only: false,
        },
        user_name: {
          value: '',
          required: false,
          read_only: false,
        },
        signature: {
          value: '',
          required: false,
          read_only: false,
        },
      },
      formErrorFlag: false,
      isAgreementChecked: false,
      formErrors: [],
      signaturePad: null,
      signatureError : false,
      agreement:null,
      agreementErr : false,
      savePopup: false,
      email: '',
      saveSuccess: false,
      answer_error: '',
      signature_flag: false,
      isEmailNull: false,
      isEmailValid: false,
      siteRedirection: null,
      carrierSpecialMessage: ''
    }
  },
  validations () {
    if (this.formFields.answer.required) {
      return {
        formFields: {
          answer: {
            value: {
              required
            }
          }
        }
      }
    }
  },
  mounted() {
    let app = this;
    app.tempId = window.localStorage.getItem('tempId');
    app.scrollTop();
    app.getAgreementTexts();
    app.getFields();
    let canvas = document.querySelector('canvas');
    app.signaturePad = new SignaturePad(canvas, {
      onEnd: () => {
        app.signaturePad.toDataURL();
      }
    });
    document.addEventListener("DOMContentLoaded", function() {
      const canvas = document.getElementById("signatureCanvas");
      const viewportWidth = window.innerWidth;
      if (viewportWidth >= 768) {
        canvas.width = 450;
        canvas.height = 300;
      } else {
        canvas.width = 200;
        canvas.height = 100;
      }
    });
  },
  methods: {
    getFields : function () {
      let app = this;
      let url = process.env.VUE_APP_API_BASE+'/get-form-fields-agreement-info';
      axios.get(url, {
        params: {
          enrollment_id: app.tempId,
        }
      })
          .then(function (response) {
            if (response.data.status == 'success') {
              response.data.data.result.forEach(function (item) {
                app.formFields[item.field_name] = item;
                if (item.field_name == 'signature') {
                  app.signature_flag = item.required;
                }
              });
              console.log('flag' + app.signature_flag);
              app.fetchInfo();
              console.log(app.formFields);
            }
          })
          .catch(function (error) {
            console.log(error);
            if (error.response.data.status == 'error') {
              error.response.data.data.forEach(function(item) {
                app.formErrorFlag = true;
                app.formErrors[item.target_element+'_message'] = item.error_message;
              });
            }
          });
    },
    getAgreementTexts: function () {
      let app = this;
      let url = process.env.VUE_APP_API_BASE+'/get-agreement-texts';
      axios.get(url, {
        params: {
          enrollment_id: app.tempId,
        }
      })
          .then(function (response) {
            if (response.data.status == 'success') {
              app.mapToAgreeementText(response.data.data.result);
              app.carrierSpecialMessage =  response.data.data.special_message;
            }
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    mapToAgreeementText (results) {
      results.forEach( params => {
        let agreementObj = {
          'text_head': params.text_head,
          'text_body': params.text_body,
          'text_required': params.text_required,
          'agreement': null,
          'agreementErr': false
        }
        this.agreementTexts.push(agreementObj)
      })
    },
    fetchInfo: function () {
      let app = this;
      let url = process.env.VUE_APP_API_BASE+'/get-enrollment-temp-info';
      axios.get(url, {
        params: {
          enrollment_id: app.tempId,
          column: 'aggrement_info',
        }
      })
          .then(function (response) {
            let fetchedData = [];
            if (response.data.status == 'success') {
              for(var i in response.data.data.result) {
                fetchedData.push([i, response.data.data.result [i]]);
              }
              console.log(fetchedData);
              fetchedData.forEach(function(item) {
                let key = item[0];
                let val = item[1];
                app.formFields[key]['value'] = val;
              });
            }
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    saveData: function () {
      console.log('inside save data')
      // check overall validation of check box.
      for ( let i = 0; i < this.agreementTexts.length; i++) {
        if (this.agreementTexts[i].agreement === null || this.agreementTexts[i].agreement === '' || !this.agreementTexts[i].agreement) {
          this.agreementTexts[i].agreementErr = true;
        } else {
          this.agreementTexts[i].agreementErr = false;
        }
      }
      // only submit agreement if above agreement value is checked
      if ((this.agreementTexts.every(agreementText => agreementText.agreement === true)) && (this.signature_flag)) {
        this.submitAgreement()
      } else if (this.agreementTexts.every(agreementText => agreementText.agreement === true)) {
        this.submitAgreement()
        } else {
          this.$v.$touch()
          if (this.$v.$invalid) {
            console.log('***** Inside Save Data Invalid*****')
            return false
          } else {
            this.submitAgreement()
          }
        }
    },
    submitAgreement () {
      let app = this;
      //eprocess to be used by request layer on API
      let eprocess = 'sigapplicant';
      if (app.formFields.agent_name.required) {
        eprocess = 'rep';
      } else if (app.formFields.group_name.required) {
        eprocess = 'group';
      } else if (app.formFields.user_name.required) {
        eprocess = 'applicant';
      } else if (app.formFields.signature.required) {
        eprocess = 'sigapplicant';
      }
      if (eprocess == 'sigapplicant' && app.signaturePad.isEmpty() && app.signature_flag) {
        app.signatureError = true;
      } else {
        const el = this.$refs.saveAgreement;
        (async () => {
          this.saveScreenShot((await html2canvas(el)).toDataURL('image/png'), 'agreement_info');
        })();
        let params = {
          enrollment_id: app.tempId,
          eprocess: eprocess,
          question_id: app.formFields.question_id.value,
          question:           app.formFields.question.value,
          answer:            app.formFields.answer.value,
          verification_code: app.formFields.verification_code.value,
          date:            app.formFields.date.value,
          agent_name:               app.formFields.agent_name.value,
          agent_code:          app.formFields.agent_code.value,
          group_name:          app.formFields.group_name.value,
          group_code:               app.formFields.group_code.value,
          user_name:       app.formFields.user_name.value,
          signature: app.formFields.signature.required ? app.signaturePad.toDataURL('image/png'): null
        };
        //discard null values
        for (const key of Object.keys(params)) {
          if (params[key] === null) {
            delete params[key];
          }
        }
        console.log(params);
        let url = process.env.VUE_APP_API_BASE+'/save-agreement-info';
        axios.post(url, params)
            .then(function (response) {
              if (response.data.status == 'success') {
                console.log("success");
                app.saveAndContinue();
              }
            })
            .catch(function (error) {
              console.log(error.response.data.data);
              if (error.response.data.status == 'error') {
                console.log('true');
                error.response.data.data.forEach(function(item) {
                  app.formErrorFlag = true;
                  app.formErrors[item.target_element+'_message'] = item.error_message;
                  //got reactivity issue
                  if (item.target_element == 'answer') {
                    app.answer_error = item.error_message;
                  }
                });
                console.log(app.formErrors);
              }
            });
      }
    },
    clear: function() {
      let app = this;
      app.signaturePad.clear();
      app.signatureError = false;
      console.log('cleared');
    },
    saveAndContinue() {
      //Update APIs
      //Navigate to next page
      this.$emit('comEnvNavigation', this.$route.name);
    },
    saveEnrollment : function () {
      let app = this;
      app.$bvModal.show('code-send');
    },
    closePopup : function () {
      let app = this;
      app.savePopup = false;
    },
    saveAndContinueEnrollment: function () {
      if (!this.isEmailValid) {
        this.isEmailNull = true;
      } else {
        let app = this;
        console.log('save called');
        let eprocess = '';
        if (app.formFields.agent_name.required) {
          eprocess = 'rep';
        } else if (app.formFields.group_name.required) {
          eprocess = 'group';
        } else if (app.formFields.user_name.required) {
          eprocess = 'applicant';
        } else if (app.formFields.signature.required) {
          eprocess = 'sigapplicant';
        }
        let params = [];
        if (eprocess == 'sigapplicant' && app.signaturePad.isEmpty()) {
          app.signatureError = false;
        } else {
          params = {
            question_id: app.formFields.question_id.value,
            question: app.formFields.question.value,
            answer: app.formFields.answer.value,
            verification_code: app.formFields.verification_code.value,
            date: app.formFields.date.value,
            agent_name: app.formFields.agent_name.value,
            agent_code: app.formFields.agent_code.value,
            group_name: app.formFields.group_name.value,
            group_code: app.formFields.group_code.value,
            user_name: app.formFields.user_name.value,
          };
        }
        //discard null values
        for (const key of Object.keys(params)) {
          if (params[key] === null) {
            delete params[key];
          }
        }
        let data = {
          enrollment_id: app.tempId,
          email: app.email,
          tab: 'aggrement_info',
          data: params
        }
        app.formErrorFlag = false;
        app.formErrors = [];
        axios.post(process.env.VUE_APP_API_BASE + '/save-enrollment', data)
            .then(function (response) {
              if (response.data.status == 'success') {
                app.saveSuccess = true;
                console.log("success");
              }
            })
            .catch(function (error) {
              if (error.response.data.status == 'error') {
                console.log('error');
                error.response.data.data.forEach(function (item) {
                  app.formErrorFlag = true;
                  app.formErrors[item.target_element + '_message'] = item.error_message;
                });
                console.log(app.formErrors);
              }
            });
      }
    },
    navigatePrevious() {
      //Update APIs
      //Navigate to next page
      this.$emit('comEnvNavigationBack', this.$route.name);
    },
    saveScreenShot (image, name) {
      let app = this;
      let data = {
        "enrollment_id" : app.tempId,
        "name" : name,
        "image" : image
      };
      let config = {
        method: 'post',
        url: process.env.VUE_APP_API_BASE+'/save-screenshot',
        headers: { },
        data : data
      };
      axios(config)
          .then(function (response) {
            if (response.data.status == 'success') {
              console.log('screenshot saved');
            }
          })
          .catch(function (error) {
            console.log('screenshot not saved');
            console.log(error);
          });
    },
    scrollTop(){
      window.scrollTo(0,0);
    },
    // check box valid state.
    checkboxValid (index) {
      this.agreementTexts[index].agreementErr = false;
    },
    removeError () {
      this.formErrors = []
      this.answer_error = ''
    },
    emailValid () {
      this.isEmailValid = checkEmail(this.email)
      this.isEmailNull = !checkEmail(this.email)
    }
  }
}
</script>

<style lang="scss">
.agreement-checkbox {
  margin: 4px -10px !important;
  width: 1.5em !important;
  height: 1.5em !important;
  box-shadow: none;
}
.agreement-text {
  position: relative !important;
  top: 3px !important;
  left: 18px !important;
}

</style>
