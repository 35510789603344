<template>
  <div class="signature-card">
    <label class="checkbox-label">
      <input type="checkbox"
             v-model="sendSmsSignature"
             @change="$emit('update:sendSmsSignature', $event.target.checked ? 1 : 0)"
             :true-value="1" :false-value="0"
             class="large-checkbox" />
      <p class="mb-0 ms-1">Send enrollment & signature notification via Text Message</p>
    </label>
  </div>
</template>

<script>
export default {
  name: "SendSMSSignature",
  data () {
    return {
      sendSmsSignature: 0
    }
  }
}
</script>

<style scoped>
.signature-card {
  padding:12px 12px 12px 8px;
  width: 494px;
  border-radius: 8px;
  background-color: #fff;
  border-left:4px solid #1E234A;
}
h2 {
  font-size: 18px;
  font-weight: 600;
  margin: 0;
}
.checkbox-label {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 16px;
}
.signature-card label {
  margin-bottom: 0;
}
.large-checkbox {
  transform: scale(1.5);
  margin: 0 2px 0 8px;
  cursor: pointer;
}
@media (max-width:767px) {
  .signature-card{
    width: 100%;
  }
}
</style>