import store from "@/store";

export function checkPlatform (platformType) {
  if (platformType == 'rep-quote') {
   return false
  } else {
   return true
  }
}



export function checkGeneleibermanDownline () {
    let isGeneDownline = store?.getters?.agentGroupLandingInfo?.is_gene_downline
    if (isGeneDownline) {
        return true
    } else {
        return false
    }
}